import { useState } from "react";

import RoiService from "../../services/roi.service";
import { IAnswers, ResultDetails, IResult } from "../../interfaces/roi";

import "./style.scss";

const defaultAnswers: IAnswers = {
  totalAccessPerDay: "",
  stafferRatePerHour: "",
  totalEmployees: "",
  totalInterruptionsPerDay: "",
};

const RoiCalculator = () => {
  const [result, setResult] = useState<IResult | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [answers, setAnswers] = useState<IAnswers>(defaultAnswers);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    const API_URL = process.env.REACT_APP_API_BASE_URL;
    event.preventDefault();

    setIsSubmitting(true);
    setIsExpanded(false);

    const config = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(answers),
    };
    try {
      const fetchResponse = await fetch(`${API_URL}/api/clientarea/Clients/calculate`, config);
      const data = await fetchResponse.json();
      setResult(data);
      setIsSubmitting(false);
      setAnswers(defaultAnswers);
    } catch (e: unknown) {
      console.error(e);
      setIsSubmitting(false);
    }
  };

  const renderQuestions = () => {
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const input = event.target;
      const thisAnswers = {
        ...answers,
        [input.name]: Number(input.value),
      };
      setAnswers(thisAnswers);
    };

    return (
      <form className="ifp-roi-calculator-app__questions-form" onSubmit={handleSubmit}>
        <div>
          <div className="ifp-roi-calculator-app__questions-form__item">
            <label htmlFor="totalAccessPerDay" className="ifp-roi-calculator-app__questions-form__item__question">
              How many times per day does someone access a file or wiki?
            </label>
            <div className="ifp-roi-calculator-app__questions-form__item__answer">
              <input
                name="totalAccessPerDay"
                id="totalAccessPerDay"
                type="number"
                step="0.1"
                value={answers.totalAccessPerDay}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <div className="ifp-roi-calculator-app__questions-form__item">
            <label htmlFor="stafferRatePerHour" className="ifp-roi-calculator-app__questions-form__item__question">
              What&apos;s your fully-loaded staffer rate/hr?
            </label>
            <div className="ifp-roi-calculator-app__questions-form__item__answer">
              <input
                name="stafferRatePerHour"
                id="stafferRatePerHour"
                type="number"
                step="0.1"
                value={answers?.stafferRatePerHour}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <div className="ifp-roi-calculator-app__questions-form__item">
            <label htmlFor="totalEmployees" className="ifp-roi-calculator-app__questions-form__item__question">
              How many employees do you have?
            </label>
            <div className="ifp-roi-calculator-app__questions-form__item__answer">
              <input
                name="totalEmployees"
                id="totalEmployees"
                type="number"
                step="0.1"
                value={answers?.totalEmployees}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <div className="ifp-roi-calculator-app__questions-form__item">
            <label
              htmlFor="totalInterruptionsPerDay"
              className="ifp-roi-calculator-app__questions-form__item__question"
            >
              How many times per day are you interrupted by a message, call, chat or unnecessary meeting?
            </label>
            <div className="ifp-roi-calculator-app__questions-form__item__answer">
              <input
                name="totalInterruptionsPerDay"
                id="totalInterruptionsPerDay"
                type="number"
                step="0.1"
                value={answers?.totalInterruptionsPerDay}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
        </div>
        <div className="ifp-roi-calculator-app__questions-form__actions">
          <button type="submit" className="ifp-roi-calculator-app__questions-form__actions__submit-btn">
            Calculate
          </button>
        </div>
      </form>
    );
  };

  const renderResult = () => {
    const showHide = isExpanded ? "hide" : "see";

    return (
      <div className="ifp-roi-calculator-app__result">
        <div className="ifp-roi-calculator-app__result__title">Estimated losses per day</div>
        <div className="ifp-roi-calculator-app__result__summary">
          <div>
            {result?.totalLossesPerDay}
            <span>per day</span>
          </div>
          <div>
            {result?.totalLossesPerMonth}
            <span>per month</span>
          </div>
        </div>
        <div className={`ifp-roi-calculator-app__result__details ifp-roi-calculator-app__result__details__${showHide}`}>
          {isExpanded && (
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th className="ifp-roi-calculator-app__result__details__td-minutes">
                    Minutes lost
                    <br />
                    per day
                  </th>
                  <th className="ifp-roi-calculator-app__result__details__td-cost">
                    Cost to
                    <br />
                    you per day
                  </th>
                </tr>
              </thead>
              <tbody>
                {result?.details.map((d: ResultDetails, index: number) => {
                  return (
                    <tr key={index}>
                      <td>
                        <strong>{d.title}</strong>
                        {d.description}
                      </td>
                      <td>
                        <div>{d.minutesLostPerDay}</div>
                      </td>
                      <td>
                        <div>{d.costPerDay}</div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          <button onClick={() => setIsExpanded(expanded => !expanded)}>{showHide} details</button>
        </div>

        <div className="ifp-roi-calculator-app__result__bottom">
          <div>
            This quote may not include all elements of a potential engagement, and is meant to give a ballpark figure
            based on the information you provide
          </div>
          <span onClick={() => setResult(null)}>reset form</span>
        </div>
      </div>
    );
  };

  const renderSubmitting = () => {
    return (
      <div className="ifp-roi-calculator-app__loading">
        <div className="loading-spinner"></div>
        <div className="ifp-roi-calculator-app__loading__text">Calculating... Please wait</div>
      </div>
    );
  };

  return (
    <div className="ifp-roi-calculator-app">
      {isSubmitting ? renderSubmitting() : result ? renderResult() : renderQuestions()}
    </div>
  );
};

export default RoiCalculator;
