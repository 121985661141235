import { FC, useState } from "react";

import { Button, Modal, Typography } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";

const { Title } = Typography;

interface IApproveDepartmentModal {
  handleClose: () => void;
  handleChange: (departmentId: number) => Promise<void>;
  departmentId: number | undefined;
}

const ApproveDepartmentModal: FC<IApproveDepartmentModal> = ({ departmentId, handleClose, handleChange }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleApprove = async () => {
    if (departmentId) {
      setIsLoading(true);
      await handleChange(departmentId);
      setIsLoading(false);
    }
  };

  return (
    <Modal
      centered
      width={795}
      footer={null}
      title={<Title level={2}>Would you like to approve this department?</Title>}
      className="dream-department-page-modal approve-department-modal"
      open={Boolean(departmentId)}
      onCancel={handleClose}
      destroyOnClose
    >
      <Button icon={<CheckCircleFilled />} onClick={handleApprove} loading={isLoading}>
        Approve Department
      </Button>
      <span onClick={handleClose}>or cancel and go back to editing</span>
    </Modal>
  );
};

export default ApproveDepartmentModal;
