import { FC, useEffect, useState } from "react";

import { Space } from "antd";

import Loading from "../../components/Loading";
import DepartmentList from "./components/DepartmentList";
import DepartmentTitle from "./components/DepartmentTitle";

import { ISummary } from "../../interfaces/clients";
import ClientService from "../../services/client.service";

import { IDreamDepartment } from "../../interfaces/dreamDepartment";
import DreamDepartmentService from "../../services/dreamDepartment.service";

import { IPageProps } from "../../interfaces/common";
import { getFromStorage } from "../../utils/utility";
import useDocumentTitle from "../../hooks/useDocumentTitle";

import "./style.scss";

const DreamDepartments: FC<IPageProps> = props => {
  useDocumentTitle(props.pageMetaTitle);

  const [summary, setSummary] = useState<ISummary>();
  const [data, setData] = useState<IDreamDepartment[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFirstTime, setIsFirstTime] = useState<boolean>(false);
  const [isApprovedAll, setIsApprovedAll] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await fetchData();
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (data.length) {
        const allApproved = data.every((d: IDreamDepartment) => d.status === 1);
        if (allApproved) {
          setIsApprovedAll(true);
        }
      }
    })();
  }, [data]);

  const fetchData = async () => {
    const response = await DreamDepartmentService.get();
    if (response?.status === 200) {
      const thisDepartments = response.data.sort(function (a: IDreamDepartment, b: IDreamDepartment) {
        return b.id - a.id;
      });
      setData(thisDepartments);
      if (thisDepartments.length) {
        const isFirstLoginStore = getFromStorage("isFirstLogin");
        const isFirstLogin = isFirstLoginStore ? true : false;
        setIsFirstTime(isFirstLogin);
        await fetchSummary();
      }
      return thisDepartments;
    } else {
      return [];
    }
  };

  const fetchSummary = async () => {
    const response = await ClientService.getSummary();
    if (response?.status === 200) {
      setSummary(response.data);
    } else {
      // eslint-disable-next-line no-console
      console.log(">>> error");
    }
  };

  const renderDepartmentTitle = () => {
    const handleApproveAll = async () => {
      const response = await ClientService.finalizeDepartments();
      if (response?.status === 200) {
        await fetchSummary();
      } else {
        // eslint-disable-next-line no-console
        console.log(">>> error");
      }
    };

    return (
      <DepartmentTitle
        summary={summary}
        departments={data}
        isLoading={isLoading}
        isApprovedAll={isApprovedAll}
        handleApproveAll={handleApproveAll}
      />
    );
  };

  return (
    <div className="dream-department-page">
      <div className="app-container">
        <Space direction="vertical" size={50} style={{ display: "flex" }}>
          {renderDepartmentTitle()}
          <section className="dream-department-page__content">
            {isLoading ? (
              <Loading message="Loading Dream Departments" />
            ) : (
              <DepartmentList
                data={data}
                setData={setData}
                isFirstTime={isFirstTime}
                fetchSummary={fetchSummary}
                setIsFirstTime={setIsFirstTime}
              />
            )}
          </section>
        </Space>
      </div>
    </div>
  );
};

export default DreamDepartments;
