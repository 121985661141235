import React from "react";

import { LoadingOutlined } from "@ant-design/icons";

import "./style.scss";

type Props = {
  text: string;
};

const LoadingOverlay = ({ text }: Props) => (
  <div className="loading-overlay">
    <LoadingOutlined spin />
    <div className="loading-text">{text}</div>
  </div>
);

export default LoadingOverlay;
