import { ChangeEvent, FC, useEffect, useRef, useState } from "react";

import { Button, Popconfirm, Spin } from "antd";
import { DeleteOutlined, EditOutlined, LinkOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";

import { DndProvider } from "react-dnd";
import type { DragDropMonitor } from "dnd-core";
import { Tree, MultiBackend, getBackendOptions } from "@minoru/react-dnd-treeview";
import { ChangeOpenHandler, InitialOpen, RenderParams, DragLayerMonitorProps } from "@minoru/react-dnd-treeview";

import { TypeIcon } from "./TypeIcon";
import { DragLayer } from "./DragLayer";
import DragDropEditModal from "./EditModal";

import { ITreeStructure, ITreeNodeData } from "../../interfaces/common";

import ErrorIcon from "../../assets/images/error-icon.png";

import "./style.scss";

interface IDragDropProps {
  treeData: ITreeStructure[];
  nodeData?: ITreeNodeData[];
  sort?: boolean;
  isExpanded?: boolean;
  isLoading?: boolean;
  handleEdit?: (id: number, values: ITreeNodeData) => Promise<void>;
  handleDelete?: (id: number) => Promise<void>;
  handleMultiDelete?: (ids: number[]) => Promise<void>;
  extraAcceptTypes?: string[];
  initialOpen?: InitialOpen | undefined;
  onChangeOpen?: ChangeOpenHandler | undefined;
  onDrop?: (tree: ITreeStructure[], options: IDropOptions) => void;
}

export interface IDropOptions {
  dragSourceId?: ITreeStructure["id"];
  dropTargetId: ITreeStructure["id"];
  monitor: DragDropMonitor;
}

const DragDrop: FC<IDragDropProps> = ({ treeData, handleEdit, handleDelete, handleMultiDelete, ...props }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const initialRef: any = null;
  const treeRef = useRef(initialRef);

  const [ids, setIds] = useState<number[]>([]);
  const [showEditModal, setShowEditModal] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(props.isLoading || false);

  useEffect(() => {
    if (treeData && props.isExpanded) {
      treeRef?.current?.openAll();
    }
  }, [treeData, props.isExpanded]);

  const renderEditModal = () => {
    const thisTreeData = treeData.find(t => t.id === Number(showEditModal));
    const thisNodeData = props.nodeData?.find(n => n.id === Number(showEditModal));

    const handleFinish = async (values: ITreeNodeData) => {
      if (handleEdit && showEditModal) {
        await handleEdit(showEditModal, values);
        console.log(">>>> values", values);
        setShowEditModal(undefined);
      }
    };

    const handleClose = () => {
      setShowEditModal(undefined);
    };

    return (
      <DragDropEditModal treeData={thisTreeData} data={thisNodeData} onFinish={handleFinish} onCancel={handleClose} />
    );
  };

  const renderNode = (node: ITreeStructure, { depth, isOpen, handleRef, onToggle }: RenderParams) => {
    const handleCheckNode = (e: ChangeEvent<HTMLInputElement>) => {
      const isChecked = e.target.checked;
      const value = e.target.value;
      let thisIds: number[] = [];
      if (isChecked) {
        thisIds = [...ids, Number(value)];
      } else {
        thisIds = ids.filter(id => id !== Number(value));
      }
      setIds(thisIds);
    };

    return (
      <div className="file-content-container">
        {node.data?.isValidType === false && (
          <div className="error-icon">
            <img src={ErrorIcon} />
          </div>
        )}
        <div
          className="file-content-wrapper"
          style={{ marginInlineStart: depth * 12, fontWeight: node.droppable ? "bold" : "normal" }}
        >
          {node.data?.isExpandable ? (
            <span className="expand-icon" style={{ cursor: "pointer" }} onClick={onToggle}>
              {isOpen ? <MinusOutlined /> : <PlusOutlined />}
            </span>
          ) : (
            <span className="expand-icon-block"></span>
          )}
          <label className="file-text" ref={handleRef}>
            <TypeIcon type={node.data?.type} />
            {node.text}
          </label>
          {handleEdit || handleDelete || handleMultiDelete || node.data?.totalLegacyLinks ? (
            <span className="folder-icons">
              {handleEdit && <EditOutlined onClick={() => setShowEditModal(node.id as number)} />}
              {!handleMultiDelete && handleDelete && (
                <Popconfirm
                  title="Delete the folder"
                  onConfirm={() => handleDelete(node.id as number)}
                  okText="Yes"
                  cancelText="No"
                >
                  <DeleteOutlined />
                </Popconfirm>
              )}
              {handleMultiDelete && (
                <input type="checkbox" name="node-id" className="node-id" value={node.id} onChange={handleCheckNode} />
              )}
              {node.data?.totalLegacyLinks ? (
                <span className="linked-icon">
                  <LinkOutlined />
                  {node.data.totalLegacyLinks}
                </span>
              ) : null}
            </span>
          ) : null}
        </div>
      </div>
    );
  };

  const renderDragPreview = (monitorProps: DragLayerMonitorProps<unknown>) => {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    if (!props.onDrop) return <></>;

    return <div>{monitorProps.item.text}</div>;
  };

  const handleDrop = async (newTree: ITreeStructure[], options: IDropOptions) => {
    if (props.onDrop) {
      setIsLoading(true);
      await props.onDrop(newTree, options);
      setIsLoading(false);
    }
  };

  const handleDeleteAll = async () => {
    const thisIds = treeData.map(t => Number(t.id));
    await handleMultiDelete?.(thisIds);
    setIds([]);
  };

  const handleDeleteSelected = async () => {
    await handleMultiDelete?.(ids);
    setIds([]);
  };

  return (
    <>
      <Spin tip="Loading..." spinning={isLoading} style={{ minHeight: 50 }}>
        <DndProvider backend={MultiBackend} options={getBackendOptions()}>
          {handleMultiDelete && (
            <div className="file-hierarchy-toolbar">
              <Popconfirm title="Delete all folders?" onConfirm={handleDeleteAll} okText="Yes" cancelText="No">
                <Button>Delete All</Button>
              </Popconfirm>
              {ids.length ? (
                <Popconfirm
                  title="Delete selected folder(s)?"
                  onConfirm={handleDeleteSelected}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button>Delete Selected</Button>
                </Popconfirm>
              ) : null}
            </div>
          )}
          <DragLayer />
          <Tree
            rootId={0}
            ref={treeRef}
            onDrop={handleDrop}
            render={renderNode}
            enableAnimateExpand
            tree={treeData || []}
            sort={props.sort || false}
            canDrag={() => Boolean(props.onDrop)}
            dragPreviewRender={renderDragPreview}
            extraAcceptTypes={props.extraAcceptTypes || []}
            classes={{
              root: "file-hierarchy-root",
              dropTarget: "file-hierarchy-drop-target",
              draggingSource: "file-hierarchy-dragging-source",
            }}
          />
        </DndProvider>
      </Spin>
      {renderEditModal()}
    </>
  );
};

export default DragDrop;
