import React from "react";

import { ITreeStructure } from "../../interfaces/common";
import styles from "./CustomDragPreview.module.css";

type Props = {
  monitorProps: {
    item: ITreeStructure;
  };
};

export const CustomDragPreview: React.FC<Props> = props => {
  const item = props.monitorProps.item;

  return (
    <div className={styles.root}>
      <div className={styles.icon}>
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 17 18" fill="none">
          <circle cx="2.07692" cy="2.07692" r="2.07692" fill="#D9D9D9" />
          <circle cx="8.30788" cy="2.07692" r="2.07692" fill="#D9D9D9" />
          <circle cx="14.5383" cy="2.07692" r="2.07692" fill="#D9D9D9" />
          <circle cx="2.07692" cy="8.99977" r="2.07692" fill="#D9D9D9" />
          <circle cx="8.30788" cy="8.99977" r="2.07692" fill="#D9D9D9" />
          <circle cx="14.5383" cy="8.99977" r="2.07692" fill="#D9D9D9" />
          <circle cx="2.07692" cy="15.9226" r="2.07692" fill="#D9D9D9" />
          <circle cx="8.30788" cy="15.9226" r="2.07692" fill="#D9D9D9" />
          <circle cx="14.5383" cy="15.9226" r="2.07692" fill="#D9D9D9" />
        </svg>
      </div>
      <div className={styles.label}>{item.text}</div>
    </div>
  );
};
