import { FC, useMemo, useState } from "react";

import { Button, Form, Modal, Select, Typography } from "antd";

import { IDreamDepartment } from "../../../interfaces/dreamDepartment";

const { Title } = Typography;

interface IDepartmentWalkThroughModal {
  isFirstTime: boolean;
  departments: IDreamDepartment[];
  handleClose: () => void;
  handleSkip: (departmentId: number) => Promise<void>;
  setShowPickTemplateModal: (department: IDreamDepartment) => void;
}

const DepartmentWalkThroughModal: FC<IDepartmentWalkThroughModal> = ({
  departments,
  isFirstTime,
  handleSkip,
  handleClose,
  setShowPickTemplateModal,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const options = useMemo(() => {
    return departments.map(department => ({
      value: department.id,
      label: department.name,
    }));
  }, [departments]);

  const onFinish = async ({ departmentId }: { departmentId: number }) => {
    const thisDepartment = departments.find(department => department.id === departmentId);
    if (!thisDepartment) return;

    if (thisDepartment.totalTemplates) {
      setShowPickTemplateModal(thisDepartment);
    } else {
      setLoading(true);
      await handleSkip(thisDepartment.id);
      setLoading(false);
    }
    handleClose();
  };

  const renderModalTitle = () => {
    return (
      <Title level={2} style={{ margin: "0 40px" }}>
        Welcome! In order to build out your new system, you need to work with each department. You&apos;ll be selecting
        one below to start, but you can edit the others later.
      </Title>
    );
  };

  return (
    <Modal
      centered
      width={1000}
      footer={null}
      title={renderModalTitle()}
      className="dream-department-page-modal initial-department-modal"
      open={isFirstTime}
      onCancel={handleClose}
      destroyOnClose
    >
      <Form form={form} name="walk-through-form" layout="inline" onFinish={onFinish}>
        <Form.Item name="departmentId" label="I want to start with this department">
          <Select
            showSearch
            options={options}
            style={{ width: 270 }}
            placeholder="Select Department..."
            filterOption={(input, option) =>
              (option?.label.toLocaleLowerCase() ?? "").includes(input.toLocaleLowerCase())
            }
          ></Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Next
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DepartmentWalkThroughModal;
