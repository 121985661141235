import React, { FC } from "react";
import { Link } from "react-router-dom";

import { Result } from "antd";

import { URL } from "../../utils/constants";

import "./style.scss";

const NoAccess: FC = () => {
  return (
    <div className="not-found-page no-access-page">
      <Result
        status="403"
        title="NO ACCESS"
        subTitle="Sorry! You don’t have permissions to view this page.  Please contact your IFP project sponsor to request access."
        extra={[
          <Link to={URL.LOGIN} className="ant-btn ant-btn-primary" key="login">
            Return to Login
          </Link>,
        ]}
      />
    </div>
  );
};

export default NoAccess;
