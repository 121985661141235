import { FC, useMemo, useRef, useState } from "react";

import type { InputRef } from "antd/es/input";
import { Button, Form, Input, Space, Spin, Typography } from "antd";
import { CheckCircleFilled, LoadingOutlined } from "@ant-design/icons";

import { BsFullscreen } from "react-icons/bs";

import DragDrop, { IDropOptions } from "../../../components/DragDrop";

import { ITreeStructure, ITreeNodeData } from "../../../interfaces/common";
import { IDreamDepartment, IDreamTemplateNodes } from "../../../interfaces/dreamDepartment";

const { Title } = Typography;

interface IDepartmentNodesProps {
  isLoadingNodes: number | undefined;
  nodes: IDreamTemplateNodes[];
  department: IDreamDepartment | undefined;
  setShowApproveDeptModal: () => void;
  setShowPickTemplateModal: () => void;
  setShowChangeDeptTypeModal: () => void;
  handleDeleteNode: (id: number) => Promise<void>;
  handleMultiDelete?: (ids: number[]) => Promise<void>;
  setShowApplyTemplateModal: () => void;
  handleAddNode: (name: string) => Promise<void>;
  handleEditNode: (id: number, values: ITreeNodeData) => Promise<void>;
  handleDropNode: (dragSourceId: number, dropTargetId: number) => Promise<void>;
}

const DepartmentNodes: FC<IDepartmentNodesProps> = ({
  nodes,
  department,
  isLoadingNodes,
  handleAddNode,
  handleEditNode,
  handleDropNode,
  handleDeleteNode,
  handleMultiDelete,
  setShowApproveDeptModal,
  setShowPickTemplateModal,
  setShowApplyTemplateModal,
  setShowChangeDeptTypeModal,
}) => {
  if (!department) {
    return null;
  }

  const [addFolderForm] = Form.useForm();
  const inputFolderNameRef = useRef<InputRef>(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeletingNodes, setIsDeletingNodes] = useState<boolean>(false);

  const hasActions = useMemo(() => {
    return [2, 4].includes(department.status) || (department.status === 0 && !department.totalTemplates);
  }, [department]);

  const treeData: ITreeStructure[] = useMemo(() => {
    return nodes.map((node: IDreamTemplateNodes) => {
      let isExpandable = false;
      for (let i = 0; i <= nodes.length; i++) {
        if (nodes[i]?.parentId === node.id) {
          isExpandable = true;
          break;
        }
      }

      return {
        id: node.id,
        parent: node.parentId ? node.parentId : 0,
        droppable: hasActions ? true : false,
        text: node.name,
        data: {
          type: node.type,
          isExpandable: isExpandable,
          structureType: department.type,
        },
      };
    });
  }, [nodes]);

  const renderAddNodeForm = () => {
    const onFinish = async (values: { name: string }) => {
      setIsSubmitting(true);
      await handleAddNode(values.name);
      addFolderForm.resetFields();
      setIsSubmitting(false);
      setTimeout(() => {
        inputFolderNameRef.current?.focus();
      });
    };

    return (
      <div className="dream-department-page__collapse__content__form">
        <Form name={`add-node-${department.id}`} form={addFolderForm} onFinish={onFinish} disabled={isSubmitting}>
          <Form.Item style={{ marginBottom: 0 }}>
            <Space.Compact>
              <Form.Item
                name="name"
                noStyle
                rules={[
                  {
                    required: true,
                    message: "Please input Node Name!",
                  },
                ]}
              >
                <Input
                  ref={inputFolderNameRef}
                  placeholder="Node Name..."
                  style={{ width: 230 }}
                  disabled={isSubmitting}
                />
              </Form.Item>
              <Button htmlType="submit" loading={isSubmitting}>
                Add Node
              </Button>
            </Space.Compact>
          </Form.Item>
        </Form>
      </div>
    );
  };

  const renderTitle = () => {
    let titleText = "";
    if (department.status === 1) {
      titleText =
        "This department structure has been approved and is now read-only. Please contact support if you would like to re-open this department and make changes.";
    } else if (department.status === 2 || (department.status === 0 && !department.totalTemplates)) {
      titleText = "Add folders and other structure to customize your department below.";
    } else if (department.status === 4) {
      titleText = "This department has new changes that need to be approved.";
    }

    return (
      <Title level={4} className={department.status === 4 ? "needs-approval-text" : ""}>
        {titleText}
      </Title>
    );
  };

  const renderActions = () => {
    return (
      <div className="dream-department-page__collapse__content__title__actions">
        <Button onClick={setShowChangeDeptTypeModal}>Change Dept. Type</Button>
        <Button
          icon={<BsFullscreen />}
          disabled={!department.totalTemplates}
          onClick={() => {
            setShowApplyTemplateModal();
            setShowPickTemplateModal();
          }}
        >
          Apply A Template
        </Button>
        <Button icon={<CheckCircleFilled />} className="btn-approve" onClick={setShowApproveDeptModal}>
          Approve Department
        </Button>
      </div>
    );
  };

  const handleDrop = async (newTree: ITreeStructure[], { dragSourceId, dropTargetId }: IDropOptions) => {
    await handleDropNode(Number(dragSourceId), Number(dropTargetId));
  };

  const renderNodes = () => {
    if (!hasActions) return <DragDrop treeData={treeData} isExpanded={department.status === 1} />;

    return (
      <DragDrop
        treeData={treeData}
        onDrop={handleDrop}
        handleEdit={handleEditNode}
        handleDelete={handleDelete}
        handleMultiDelete={handleMultiDelete}
        nodeData={nodes as ITreeNodeData[]}
      />
    );
  };

  const handleDelete = async (id: number) => {
    if (handleDeleteNode) {
      setIsDeletingNodes(true);
      await handleDeleteNode(id);
      setIsDeletingNodes(false);
    }
  };

  return (
    <div className="dream-department-page__collapse__content">
      <div className={`dream-department-page__collapse__content__title ${hasActions ? "has-actions" : ""}`}>
        {renderTitle()}
        {hasActions && renderActions()}
      </div>
      {hasActions && renderAddNodeForm()}
      <Spin tip="Deleting..." spinning={isDeletingNodes}>
        <div className="dream-department-page__collapse__content__nodes">
          {isLoadingNodes === department.id && !isDeletingNodes ? (
            <span className="dream-department-page__collapse__content__nodes__fetching">
              <Spin indicator={<LoadingOutlined style={{ fontSize: 18 }} spin />} /> Fetching Nodes...
            </span>
          ) : treeData.length ? (
            renderNodes()
          ) : (
            <span className="dream-department-page__collapse__content__nodes__empty">
              Your department is empty - add structure above
            </span>
          )}
        </div>
      </Spin>
    </div>
  );
};

export default DepartmentNodes;
