import { FC, useEffect, useState } from "react";

import { Button, Form, Input, Modal, Select } from "antd";

import { FILE_HIERARCHY_NODE_TYPE } from "../../utils/data";
import { ITreeStructure, ITreeNodeData, IOptions } from "../../interfaces/common";

interface IDragDropEditModalProps {
  data: ITreeNodeData | undefined;
  treeData: ITreeStructure | undefined;
  onFinish: (values: ITreeNodeData) => Promise<void>;
  onCancel: () => void;
}

const modalFormLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 },
};

const DragDropEditModal: FC<IDragDropEditModalProps> = ({ data, treeData, onFinish, onCancel }) => {
  const [form] = Form.useForm();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [options, setOptions] = useState<IOptions[]>([]);

  useEffect(() => {
    form.resetFields();

    if (data) {
      form.setFieldsValue(data);
    }
  }, [data]);

  useEffect(() => {
    if (treeData) {
      if (treeData.data?.structureType === 1) {
        setOptions([{ label: "Folder", value: 5 }]);
      } else if (treeData.data?.structureType === 3) {
        setOptions([
          { label: "Folder", value: 5 },
          { label: "File Library", value: 7 },
          { label: "Sub-Site", value: 6 },
        ]);
      } else if (treeData.data?.structureType === 2) {
        setOptions([
          { label: "Folder", value: 5 },
          { label: "Channel", value: 8 },
          { label: "Private Channel", value: 9 },
          { label: "Shared Channel", value: 10 },
        ]);
      }
    }
  }, [treeData]);

  const handleOk = () => {
    form.submit();
  };

  const handClose = () => {
    onCancel();
    setIsSubmitting(false);
  };

  const handleSubmit = async (values: ITreeNodeData) => {
    setIsSubmitting(true);
    await onFinish(values);
    form.resetFields();
    setIsSubmitting(false);
  };

  return (
    <Modal
      centered
      title={<h2>Edit Node</h2>}
      okText="Save"
      destroyOnClose
      open={Boolean(data)}
      onOk={handleOk}
      onCancel={handClose}
      className="node-edit-modal"
      footer={[
        <Button key="back" onClick={handClose} disabled={isSubmitting}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={isSubmitting} onClick={handleOk}>
          {isSubmitting ? "Saving..." : "Save"}
        </Button>,
      ]}
    >
      <Form
        {...modalFormLayout}
        form={form}
        name="add-client"
        onFinish={handleSubmit}
        disabled={isSubmitting}
        style={{ marginBottom: 30 }}
      >
        <Form.Item label="Name" name="name">
          <Input placeholder="Enter Name..." />
        </Form.Item>
        <Form.Item
          label="Node Type"
          name="type"
          style={{ display: treeData?.data?.structureType === 1 ? "none" : "block" }}
        >
          <Select
            showSearch
            allowClear
            placeholder="Select Node Type..."
            filterOption={(input, option) =>
              (option?.label.toLocaleLowerCase() ?? "").includes(input.toLocaleLowerCase())
            }
            options={options}
          />
        </Form.Item>
        {/* <Form.Item label="Node Owner" name="owner">
          <Input placeholder="Enter Node Owner..." />
        </Form.Item>
        <Form.Item label="Purpose" name="purpose">
          <Input.TextArea placeholder="Enter Purpose..." rows={3} />
        </Form.Item>
        <Form.Item label="Permission Notes" name="permissionNotes">
          <Input.TextArea placeholder="Enter Permission Notes..." rows={3} />
        </Form.Item>
        <Form.Item label="Notes" name="notes">
          <Input.TextArea placeholder="Enter Notes..." rows={3} />
        </Form.Item> */}
      </Form>
    </Modal>
  );
};

export default DragDropEditModal;
