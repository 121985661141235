import { getFromStorage } from "../utils/utility";

const useAuth = () => {
  const clientAccessToken = getFromStorage("clientAccessToken");
  if (clientAccessToken) {
    return clientAccessToken;
  }
  return null;
};

export default useAuth;
