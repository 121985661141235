import { FC, useMemo, useState } from "react";

import { Button, Modal, Typography, notification } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

import { toTree } from "../../../utils/utility";
import { TypeIcon } from "../../../components/DragDrop/TypeIcon";
import { IDreamDepartment, IDreamTemplateNodes } from "../../../interfaces/dreamDepartment";

const { Title } = Typography;

interface ISelectNodeModal {
  handleClose: () => void;
  nodes: IDreamTemplateNodes[];
  templateId: number | undefined;
  department: IDreamDepartment | undefined;
  handleSelectNode: (dreamStructureNodeId: number) => Promise<void>;
}

const SelectNodeModal: FC<ISelectNodeModal> = ({ nodes, templateId, department, handleClose, handleSelectNode }) => {
  if (!department) return;

  const [api, contextHolder] = notification.useNotification();

  const [isLoading, setIsLoading] = useState<number>(-1);

  const tree = useMemo(() => {
    return toTree(nodes);
  }, [nodes]);

  const openNotificationWithIcon = () => {
    api.success({
      message: "Success",
      description: "Template Selected",
    });
  };

  const renderModalTitle = () => {
    return (
      <>
        <Title level={2}>
          The “{department?.name}” department has some pre-existing structure already. Where would you like to place
          your new template?
        </Title>
        <Title level={5} className="danger-text">
          <span onClick={handleClose}>If you don&apos;t want to do this, cancel this action</span>
        </Title>
      </>
    );
  };

  const renderNode = (item: IDreamTemplateNodes | undefined) => {
    const handleSelectRoot = async () => {
      setIsLoading(item?.id || 0);
      await handleSelectNode(item?.id || 0);
      openNotificationWithIcon();
      setIsLoading(-1);
    };

    let setLoading = false;
    if (!item && isLoading === 0) {
      setLoading = true;
    } else if (isLoading === item?.id) {
      setLoading = true;
    }

    return (
      <>
        <li className="select-node-modal__nodes__item" key={`main-${department?.id}-${item?.id || "root-key"}`}>
          <div className="select-node-modal__nodes__item__name">
            <span>{item && <TypeIcon type={item.type} />}</span>
            {item?.name || "In The Root Folder"}
          </div>
          <Button
            onClick={handleSelectRoot}
            icon={<ArrowLeftOutlined />}
            loading={setLoading}
            disabled={!setLoading && isLoading > -1}
          >
            Select {item ? "Node" : "Root"}
          </Button>
        </li>
        {item?.children?.length && (
          <li className="select-node-modal__nodes__item has-child" key={`child-${department?.id}-${item.id}`}>
            {renderSubNodes(item.children)}
          </li>
        )}
      </>
    );
  };

  const renderSubNodes = (subNodes: IDreamTemplateNodes[]) => {
    return <ul className="select-node-modal__nodes child-nodes">{subNodes.map(renderNode)}</ul>;
  };

  return (
    <>
      {contextHolder}
      <Modal
        centered
        width={795}
        footer={null}
        destroyOnClose
        onCancel={handleClose}
        title={renderModalTitle()}
        open={Boolean(templateId)}
        className="dream-department-page-modal select-node-modal"
      >
        <ul className="select-node-modal__nodes">
          {renderNode(undefined)}
          {tree.map(renderNode)}
        </ul>
      </Modal>
    </>
  );
};

export default SelectNodeModal;
