import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { ConfigProvider } from "antd";

import "./index.scss";
import Routes from "./routes";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import RoiCalculator from "./iframe/RoiCalculator";

import { msalInstance } from "./authConfig";
import { AuthenticationResult, EventMessage, EventType } from "@azure/msal-browser";

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    if (account) {
      msalInstance.setActiveAccount(account);
    }
  }
});

const rootElem = document.getElementById("root");
if (rootElem) {
  const root = ReactDOM.createRoot(rootElem);
  root.render(
    <ConfigProvider theme={{ hashed: false }}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </ConfigProvider>
  );
}

const rioElem = document.getElementById("iFPRoiCalculator");
if (rioElem) {
  const root = ReactDOM.createRoot(rioElem);
  root.render(
    <React.StrictMode>
      <RoiCalculator />
    </React.StrictMode>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
