import API from "./axios";

import { IPatchDocument } from "../interfaces/common";

class DreamDepartmentService {
  get = () => {
    return API.get("/api/clientarea/DreamStructures")
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  getById = (id: number) => {
    return API.get(`/api/clientarea/DreamStructures/${id}`)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  update = (id: number, values: IPatchDocument[]) => {
    return API.patch(`/api/clientarea/DreamStructures/${id}`, values)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  getCount = () => {
    return API.get("/api/clientarea/DreamStructures/count")
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  getTemplates = (id: number) => {
    return API.get(`/api/clientarea/DreamStructures/${id}/templates`)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  getNodes = (id: number) => {
    return API.get(`/api/clientarea/DreamStructures/${id}/nodes`)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  addNode = (id: number, name: string) => {
    return API.post(`/api/clientarea/DreamStructures/${id}/nodes`, { name: name })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  updateNode = (id: number, nodeId: number, values: IPatchDocument[]) => {
    return API.patch(`/api/clientarea/DreamStructures/${id}/nodes/${nodeId}`, values)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  deleteNode = (id: number, nodeId: number) => {
    return API.delete(`/api/clientarea/DreamStructures/${id}/nodes/${nodeId}`)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  deleteNodes = (id: number, nodeIds: number[]) => {
    return API.delete(`/api/clientarea/DreamStructures/${id}/nodes?nodesId=${nodeIds.join("&nodesId=")}`)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  changeStatus = (id: number, status: "approve" | "skip", clientId: number) => {
    return API.post(`/api/clientarea/DreamStructures/${id}/${status}`, { clientId })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  assignTemplates = (id: number, templateId: number, clientId: number, dreamStructureNodeId: number = 0) => {
    return API.post(`/api/clientarea/DreamStructures/${id}/template/${templateId}/assign`, {
      clientId,
      dreamStructureNodeId,
    })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };
}

export default new DreamDepartmentService();
