import { FC } from "react";

import TeamRoadMap from "./sections/team-road-map";
import TeamsSharepointOverview from "./sections/teams-sharepoint-overview";
import TeamsSharepointDetails from "./sections/teams-sharepoint-details";

import { IPageProps } from "../../interfaces/common";
import useDocumentTitle from "../../hooks/useDocumentTitle";

import "./style.scss";

const Dashboard: FC<IPageProps> = (props: IPageProps) => {
  useDocumentTitle(props.pageMetaTitle);

  return (
    <div className="dashboard-page">
      <TeamRoadMap />
      <TeamsSharepointOverview />
      <TeamsSharepointDetails />
    </div>
  );
};

export default Dashboard;
