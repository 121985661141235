import React, { FC, useEffect, useState } from "react";
import { useLocation, useNavigate, Navigate } from "react-router-dom";

import { Alert } from "antd";

import LoadingOverlay from "../../components/LoadingOverlay";

import useAuth from "../../hooks/useAuth";
import useMsalAuth from "../../hooks/useMsalAuth";

import { URL } from "../../utils/constants";
import { ILocation } from "../../interfaces/common";
import Typography from "../../components/Typography";
import AuthService from "../../services/auth.service";
import { getActivityLogs } from "../../utils/utility";
import ClientService from "../../services/client.service";
import { clearStorage, getUrlParams, setInStorage } from "../../utils/utility";

import loginBtn from "./microsoft-signin-dark.svg";
import "./style.scss";

const Auth: FC = () => {
  const client = useAuth();
  const msalAuth = useMsalAuth();
  const navigate = useNavigate();
  const location: ILocation = useLocation();
  const status = getUrlParams("status");

  const [message, setMessage] = useState("");
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  useEffect(() => {
    // if the user is already logged in, no need to do it again
    if (msalAuth && client) {
      setRedirectToReferrer(true);
    }

    if (status) {
      setMessage("Unauthorized Access... Please re-authenticate");
    }
  }, [msalAuth, status]);

  if (redirectToReferrer) {
    let redirectUrl = null;
    if (typeof location.state == "string") {
      const state = JSON.parse(location.state);
      redirectUrl = state?.from;
    }

    return <Navigate to={redirectUrl || URL.DASHBOARD} replace />;
  }

  const getClients = async () => {
    const response = await ClientService.getClients();
    if (response?.status === 200) {
      const clients = response.data;
      setInStorage("ifpClients", JSON.stringify(clients));
      setInStorage("ifpCurrentClient", clients[0].id);
    }
  };

  const login = async () => {
    setMessage("");
    try {
      setIsLoggingIn(true);
      const response = await AuthService.msalLogin();
      if (response.accessToken) {
        await validateMsalAccessToken(response.accessToken);
      } else {
        navigate(URL.NO_ACCESS);
      }
      setIsLoggingIn(false);
    } catch (err: unknown) {
      // eslint-disable-next-line no-console
      console.log("error logging in: " + err);
      setIsLoggingIn(false);
    }
  };

  const validateMsalAccessToken = async (accessToken: string) => {
    const response = await AuthService.validateMsalToken(accessToken);

    if (response.status === 200 && response.data?.token) {
      setInStorage("clientAccessToken", response.data.token);
      await getClients();
      await getActivityLogs();
    } else if (response.status === 403) {
      clearStorage();
      navigate(URL.NO_ACCESS);
    } else {
      clearStorage();
      navigate(`${URL.LOGIN}?status=${response.status}`);
    }
  };

  return (
    <div className="login-page">
      {isLoggingIn && <LoadingOverlay text="Please wait... Logging In" />}
      <div className="login-box">
        <div className="login-logo"></div>
        <div className="login-form">
          <Typography variant="h5" className="login-title">
            Login to the IFP Client Portal
          </Typography>
          {message && <Alert message={message} type="error" showIcon closable afterClose={() => setMessage("")} />}

          <img className="login-btn" src={loginBtn} alt="Sign in with Microsoft" onClick={login} />
        </div>
      </div>
    </div>
  );
};

export default Auth;
