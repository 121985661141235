import { FC, useState } from "react";

import { Button, Modal, Typography } from "antd";

import DreamTemplates from "./Templates";

import { IDreamDepartment, IDreamTemplates } from "../../../interfaces/dreamDepartment";

const { Title } = Typography;

interface IPickTemplateModalProps {
  isApplyModal: boolean;
  handleSkip: () => Promise<void>;
  handleClose: () => void;
  isLoadingTemplates: boolean;
  templates: IDreamTemplates[];
  data: IDreamDepartment | undefined;
  handleSelectTemplate: (templateId: number) => Promise<void>;
}

const PickTemplateModal: FC<IPickTemplateModalProps> = ({
  data,
  templates,
  isApplyModal,
  handleSkip,
  handleClose,
  isLoadingTemplates,
  handleSelectTemplate,
}) => {
  const [loading, setLoading] = useState(false);

  const renderModalTitle = () => {
    if (isApplyModal) {
      return (
        <>
          <Title level={2}>Apply a template</Title>
          <Title level={5} className="danger-text">
            Applying another template won&apos;t affect your current structure, it will just append the new structure
            into your department.{" "}
            <span onClick={handleClose}>If you don&apos;t want to do this, click here to cancel</span>, otherwise select
            a template below.
          </Title>
        </>
      );
    } else {
      return (
        <>
          <Title level={2}>
            Pick a starting template for the &ldquo;{data?.name}&rdquo; department, then start customizing
          </Title>
          <Title level={5}>
            If you don&apos;t like your choice, you can always start over again until it&apos;s right or call our
            consultation team for help.
          </Title>
        </>
      );
    }
  };

  const handleSkipProcess = async () => {
    setLoading(true);
    await handleSkip();
    setLoading(false);
  };

  return (
    <Modal
      centered
      width={1200}
      footer={null}
      title={renderModalTitle()}
      className={`dream-department-page-modal pick-template-modal ${isApplyModal ? "apply-template-modal" : ""}`}
      open={Boolean(data)}
      onCancel={handleClose}
    >
      <DreamTemplates
        data={templates}
        hasNodes={data?.hasNodes}
        isLoading={isLoadingTemplates}
        handleSelectTemplate={handleSelectTemplate}
      />
      {!isApplyModal && (
        <div className="pick-template-modal__skip">
          <Button onClick={handleSkipProcess} loading={loading}>
            Skip this step
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default PickTemplateModal;
