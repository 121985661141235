import { Fragment, FC } from "react";
import { format } from "date-fns";

import { Empty, Typography } from "antd";
const { Title } = Typography;

import TableSkeleton from "../../../components/Skeleton/Table";

import { IClientTeams, IClientTeamChannels } from "../../../interfaces/clients";

interface ITeamsDetails {
  isLoading: boolean;
  teamData: IClientTeams[] | undefined;
}

const TeamsDetails: FC<ITeamsDetails> = ({ isLoading, teamData }: ITeamsDetails) => {
  const renderTableBodyContent = (rowData: IClientTeams) => {
    return (
      <>
        <td>{rowData.teamName}</td>
        <td>{rowData.qtyFiles.toLocaleString("en-US")}</td>
        <td>
          {rowData.qtyFiles && rowData.lastFileUpdate ? format(new Date(rowData.lastFileUpdate), "MM/dd/yyyy") : ""}
        </td>
      </>
    );
  };

  const renderTableBodyChannels = (rowData: IClientTeamChannels, index: number) => {
    return (
      <tr key={index} className="teams-sharepoint-details-item__channels">
        <td>{rowData.channelName}</td>
        <td>{rowData.qtyFiles.toLocaleString("en-US")}</td>
        <td>
          {rowData.qtyFiles && rowData.lastFileUpdate ? format(new Date(rowData.lastFileUpdate), "MM/dd/yyyy") : ""}
        </td>
      </tr>
    );
  };

  const renderTableBody = () => {
    return teamData?.map((rowData: IClientTeams, index: number) => {
      return (
        <Fragment key={index}>
          <tr>{renderTableBodyContent(rowData)}</tr>
          {rowData.channels?.length && rowData.channels.map(renderTableBodyChannels)}
        </Fragment>
      );
    });
  };

  const renderTable = () => {
    return (
      <table className="app-table teams-sharepoint-details-item__table">
        <thead>
          <tr>
            <th>Name</th>
            <th className="td-files-qty">QTY Files</th>
            <th className="td-updated-at">Last File Update</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <TableSkeleton rowsCount={3} columnsCount={3} />
          ) : teamData?.length ? (
            renderTableBody()
          ) : (
            <tr>
              <td colSpan={3} className="app-table-no-data">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  return (
    <div className="teams-sharepoint-details-item">
      <Title level={2} className="teams-sharepoint-details-item__title">
        TEAMS
      </Title>
      {renderTable()}
    </div>
  );
};

export default TeamsDetails;
