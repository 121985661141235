import { useEffect, useState } from "react";
import { format } from "date-fns";

import { Col, Empty, Row, Typography } from "antd";
const { Title } = Typography;

import TeamsDetails from "./teams-details";
import SharepointDetails from "./sharepoint-details";

import ClientService from "../../../services/client.service";
import { IClientTeams, IClientSharepointSite } from "../../../interfaces/clients";

import "./teams-sharepoint-details.scss";

const TeamsSharepointDetails = () => {
  const [lastUpdatedData, setLastUpdatedData] = useState<string>("");
  const [isLoadingTeamsData, setIsLoadingTeamsData] = useState<boolean>(false);
  const [isLoadingSharePointData, setIsLoadingSharePointData] = useState<boolean>(false);
  const [teamsData, setTeamsData] = useState<IClientTeams[] | undefined>(undefined);
  const [sharePointData, setSharepointData] = useState<IClientSharepointSite[] | undefined>(undefined);

  useEffect(() => {
    (async () => {
      await fetchData();
      await fetchTeamsData();
      await fetchSharePointData();
    })();
  }, []);

  const fetchData = async () => {
    const response = await ClientService.getLatest();
    if (response?.status === 200) {
      const date = format(new Date(response.data.requestedDate), "MM/dd/yyyy h:m a");
      setLastUpdatedData(date);
    }
  };

  const fetchTeamsData = async () => {
    setIsLoadingTeamsData(true);
    const response = await ClientService.getTeamsData();
    if (response?.status === 200) {
      setTeamsData(response.data);
    }
    setIsLoadingTeamsData(false);
  };

  const fetchSharePointData = async () => {
    setIsLoadingSharePointData(true);
    const response = await ClientService.getSharePointData();
    if (response?.status === 200) {
      setSharepointData(response.data);
    }
    setIsLoadingSharePointData(false);
  };

  return (
    <div className="teams-sharepoint-details-section">
      <div className="app-container">
        <Title className="app-section-title">
          TEAMS/SHAREPOINT DETAILS
          <small>last updated {lastUpdatedData}</small>
        </Title>

        {teamsData?.length || sharePointData?.length ? (
          <Row gutter={{ lg: 120, md: 60, sm: 0 }}>
            <Col lg={12} md={12} sm={24}>
              <TeamsDetails isLoading={isLoadingTeamsData} teamData={teamsData} />
            </Col>
            <Col lg={12} md={12} sm={24}>
              <SharepointDetails isLoading={isLoadingSharePointData} sharepointData={sharePointData} />
            </Col>
          </Row>
        ) : (
          <div style={{ marginTop: 40 }}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>Audit data not found.</span>} />
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamsSharepointDetails;
