import { Navigate, Outlet, useLocation } from "react-router-dom";

import Layout from "./components/Layout";

import useAuth from "./hooks/useAuth";
import { URL } from "./utils/constants";
import useMsalAuth from "./hooks/useMsalAuth";

const PrivateOutlet = () => {
  const client = useAuth();
  const msalAuth = useMsalAuth();
  const rLocation = useLocation();

  return msalAuth && client ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to={URL.LOGIN} state={JSON.stringify({ from: rLocation.pathname })} replace />
  );
};

export default PrivateOutlet;
