import { FC } from "react";

import { format } from "date-fns";

import { Button, Typography } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";

import { ISummary } from "../../../interfaces/clients";

import { IDreamDepartment } from "../../../interfaces/dreamDepartment";

const { Title } = Typography;

interface IDepartmentTitleProps {
  isLoading: boolean;
  isApprovedAll: boolean;
  summary: ISummary | undefined;
  departments: IDreamDepartment[];
  handleApproveAll: () => void;
}

const DepartmentTitle: FC<IDepartmentTitleProps> = ({
  isLoading,
  summary,
  departments,
  isApprovedAll,
  handleApproveAll,
}) => {
  const renderTitleText = () => {
    const mainText = (
      <p>
        Based on your feedback at the workshop, we have some suggested architectures you can play around with for each
        one of your departments. Some have multiple options you can choose from, with a few having both Teams and
        SharePoint Site options.
      </p>
    );

    if (!departments.length) {
      return (
        <p>
          Your organization has not gotten to this stage in the process yet. Please contact your account manager for
          more information on how to access this feature.
        </p>
      );
    }

    if (!isApprovedAll) {
      return (
        <>
          {mainText}
          <Title level={3}>
            Start by clicking the yellow buttons below to choose a layout for each department and start customizing!
          </Title>
        </>
      );
    }

    if (summary?.lastApprovedAt) {
      const date = format(new Date(summary.lastApprovedAt), "hh:mm aaaa, MM/dd/yyyy");
      if (!summary.hasFinalizedDepartments) {
        return (
          <>
            {mainText}
            <Title level={3} className="text-red">
              Final approval for these departments was given on {date} by {summary.lastApprovedBy}, but new changes have
              been made and approval needs to be given again.
            </Title>
            <div className="dream-department-page__title__action">
              <Button icon={<CheckCircleFilled />} onClick={handleApproveAll}>
                Give Final Approval
              </Button>
            </div>
          </>
        );
      }

      return (
        <>
          {mainText}
          <Title level={3}>
            Final structure has been approved. Further changes can be made by your consultant, but may add additional
            charges and require re-approval.
          </Title>
          <Title level={3} className="text-green">
            Final approval by {summary.lastApprovedBy} at {date}
          </Title>
        </>
      );
    }

    return (
      <>
        {mainText}
        <Title level={3}>
          All departments have now had initial approval. Please review each department below and give final approval for
          all. If you cannot give approval, contact your consultant to make additional changes. If further changes are
          needed, re-approval will be required.
        </Title>
        <div className="dream-department-page__title__action">
          <Button icon={<CheckCircleFilled />} onClick={handleApproveAll}>
            Give Final Approval
          </Button>
        </div>
      </>
    );
  };

  return (
    <section className="dream-department-page__title">
      <Title>Department Structure</Title>
      {!isLoading && renderTitleText()}
    </section>
  );
};

export default DepartmentTitle;
