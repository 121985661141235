import { FC, useState } from "react";

import { Button, Form, Modal, Select, Typography } from "antd";

import { DEPARTMENT_TYPE } from "../../../utils/data";
import { IDreamDepartment } from "../../../interfaces/dreamDepartment";

const { Title } = Typography;

interface IChangeDeptTypeModal {
  handleClose: () => void;
  handleChange: (departmentId: number, type: number) => Promise<void>;
  department: IDreamDepartment | undefined;
}

const ChangeDeptTypeModal: FC<IChangeDeptTypeModal> = ({ department, handleClose, handleChange }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  const onFinish = async (values: { departmentType: number }) => {
    if (department) {
      setIsLoading(true);
      await handleChange(department.id, values.departmentType);
      setIsLoading(false);
      handleClose();
    }
  };

  const renderModalTitle = () => {
    const type = DEPARTMENT_TYPE.find(d => d.value === department?.type)?.label;
    return (
      <>
        <Title level={2}>
          Your department is currently a {type}, <br />
          would you like to change this department to a different type?
          <br />
          You can choose from the following options:
          <br />
          Sandbox, Team, or SharePoint Site.
        </Title>
        <Title level={5} className="danger-text">
          If you change the type, all the nodes will be reset to being folders, but the structure and names will be
          saved. After that you can change each node to what you want.{" "}
          <span onClick={handleClose}>Are you sure you want to do this? If not, cancel this action.</span>
        </Title>
      </>
    );
  };

  return (
    <Modal
      centered
      width={795}
      footer={null}
      title={renderModalTitle()}
      className="dream-department-page-modal change-department-type-modal"
      open={Boolean(department)}
      onCancel={handleClose}
      destroyOnClose
    >
      <Form
        form={form}
        name="change-department-form"
        layout="inline"
        onFinish={onFinish}
        disabled={isLoading}
        initialValues={{ departmentType: department?.type }}
      >
        <Form.Item name="departmentType" label="Change this department to a">
          <Select style={{ width: 270 }} options={DEPARTMENT_TYPE}></Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ChangeDeptTypeModal;
