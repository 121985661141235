import { FC } from "react";
import { format } from "date-fns";

import { Empty, Typography } from "antd";
const { Title } = Typography;

import TableSkeleton from "../../../components/Skeleton/Table";

import { IClientSharepointSite } from "../../../interfaces/clients";

interface ISharepointDetails {
  isLoading: boolean;
  sharepointData: IClientSharepointSite[] | undefined;
}

const SharepointDetails: FC<ISharepointDetails> = ({ isLoading, sharepointData }: ISharepointDetails) => {
  const renderTableBody = (rowData: IClientSharepointSite, index: number) => {
    return (
      <tr key={index}>
        <td>{rowData.siteName}</td>
        <td>{rowData.qtyFiles.toLocaleString("en-US")}</td>
        <td>
          {rowData.qtyFiles && rowData.lastFileUpdate ? format(new Date(rowData.lastFileUpdate), "MM/dd/yyyy") : ""}
        </td>
      </tr>
    );
  };

  const renderTable = () => {
    return (
      <table className="app-table teams-sharepoint-details-item__table">
        <thead>
          <tr>
            <th>Name</th>
            <th className="td-files-qty">QTY Files</th>
            <th className="td-updated-at">Last File Update</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <TableSkeleton rowsCount={3} columnsCount={3} />
          ) : sharepointData?.length ? (
            sharepointData.map(renderTableBody)
          ) : (
            <tr>
              <td colSpan={3} className="app-table-no-data">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  return (
    <div className="teams-sharepoint-details-item">
      <Title level={2} className="teams-sharepoint-details-item__title">
        SHAREPOINT SITES
      </Title>
      {renderTable()}
    </div>
  );
};

export default SharepointDetails;
