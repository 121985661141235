import API from "./axios";

import { type EndSessionPopupRequest } from "@azure/msal-browser";
import { msalInstance, loginRequest } from "../authConfig";

class AuthService {
  msalLogin() {
    return msalInstance
      .loginPopup(loginRequest)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      });
  }

  msalLogout() {
    const logoutRequest: EndSessionPopupRequest = {
      mainWindowRedirectUri: "/login",
    };
    return msalInstance
      .logoutPopup(logoutRequest)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      });
  }

  getAccount = () => {
    return msalInstance
      .acquireTokenSilent(loginRequest)
      .then(response => {
        return response;
      })
      .catch(err => {
        console.log("err", err);
        return null;
      });
  };

  validateMsalToken(accessToken: string) {
    return API.post(
      "/api/clientarea/Users/AuthViaAzureAD",
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }
}

export default new AuthService();
