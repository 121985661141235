import axios from "axios";

import { API_PREFIX, URL } from "../utils/constants";
import { getFromStorage, clearStorage } from "../utils/utility";

const API = axios.create({
  // .. where we make our configurations
  baseURL: API_PREFIX,
  timeout: 60000, // Now all requests using this instance will wait 60 seconds before timing out
});

API.defaults.headers.get["Accept"] = "application/json";

API.defaults.headers.post["Accept"] = "application/json";
API.defaults.headers.post["Content-Type"] = "application/json";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
API.interceptors.request.use((config: any) => {
  const clientAccessToken = getFromStorage("clientAccessToken");
  const currentClient = getFromStorage("ifpCurrentClient");
  if (clientAccessToken && config) {
    config.headers.Authorization = `Bearer ${clientAccessToken}`;
  }
  if (currentClient) {
    config.headers.clientId = currentClient;
  }
  return config;
});

API.interceptors.response.use(
  response => responseSuccessHandler(response),
  error => responseErrorHandler(error)
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const responseSuccessHandler = (response: any) => {
  return response;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const responseErrorHandler = (error: any) => {
  // eslint-disable-next-line no-console
  console.log("<>err<>", error.response.status);

  if (error.response.status === 401) {
    const url = error.response.config.url === "/api/clientarea/User/AuthViaAzureAD" ? URL.NO_ACCESS : URL.LOGIN;
    clearStorage();
    return window.location.replace(url);
  }

  return Promise.reject(error);
};

export default API;
