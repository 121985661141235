import KeyFiles from "../pages/KeyFiles";
import Dashboard from "../pages/Dashboard";
import DreamDepartments from "../pages/DreamDepartments";

import { URL } from "../utils/constants";
import { IPage } from "../interfaces/common";

const PAGES: Array<IPage> = [
  {
    id: 1,
    name: "Dashboard",
    element: <Dashboard pageTitle="Dashboard" pageMetaTitle="Dashboard" />,
    link: URL.DASHBOARD,
    isPage: true,
    access: [],
  },
  {
    id: 2,
    name: "Structure",
    element: <DreamDepartments pageTitle="Dream Structure" pageMetaTitle="Dream Structure" />,
    link: URL.DREAM_DEPARTMENTS,
    isPage: true,
    access: [],
  },
  {
    id: 3,
    name: "Key Files",
    element: <KeyFiles pageTitle="Key Files" pageMetaTitle="Key Files" />,
    link: URL.KEY_FILES,
    isPage: true,
    access: [],
  },
];

export default PAGES;
