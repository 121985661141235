export const REPORT_STATUS = ["Not Run", "Pending", "Succeeded", "Failed"];

export const DEPARTMENT_TYPE = [
  { label: "Sandbox", value: 1 },
  { label: "Team", value: 2 },
  { label: "Sharepoint Site", value: 3 },
];

export const MODULE_TYPE = [
  { label: "Client Portal Users", value: 1 },
  { label: "Client Portal Departments", value: 2 },
  { label: "Client Portal_Key Files Site", value: 3 },
];

export const STRUCTURE_TYPE = [
  { label: "Sharepoint Site", value: 1 },
  { label: "Folder", value: 2 },
  { label: "File Library", value: 3 },
  { label: "Channel", value: 4 },
  { label: "Private Channel", value: 9 },
  { label: "Shared Channel", value: 10 },
];

export const FILE_HIERARCHY_NODE_TYPE = [
  { label: "Department", value: 1 },
  { label: "Team", value: 2 },
  { label: "Sandbox", value: 3 },
  { label: "Sharepoint Site", value: 4 },
  { label: "Folder", value: 5 },
  { label: "Sub-Site", value: 6 },
  { label: "File Library", value: 7 },
  { label: "Channel", value: 8 },
  { label: "Private Channel", value: 9 },
  { label: "Shared Channel", value: 10 },
];

export const APPROVAL_STATUS = [
  {
    id: 0,
    name: "None",
  },
  {
    id: 1,
    name: "Approved",
  },
  {
    id: 2,
    name: "Modified",
  },
  {
    id: 3,
    name: "Rejected",
  },
  {
    id: 4,
    name: "Pending Approval", // Earlier state approved, changed to pending approval state after user makes changes
  },
  {
    id: 5,
    name: "Approval Changed", // Earlier state approved, changed to approval changed state after user approves another template
  },
];
