import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Result, Spin } from "antd";

import ClientService from "../../services/client.service";
import useQueryString from "../../hooks/useQueryString";
import useDocumentTitle from "../../hooks/useDocumentTitle";

import "./style.scss";

const RecordConsent: FC = () => {
  useDocumentTitle("Record Consent");

  const queryString = useQueryString();
  const adminConsent = queryString.get("admin_consent");
  const tenantId = queryString.get("tenant");
  const token = queryString.get("state");

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(queryString.get("error"));

  useEffect(() => {
    if (!error && adminConsent === "True") {
      (async () => {
        await recordConsent();
      })();
    }
  }, [error, adminConsent]);

  const recordConsent = async () => {
    if (tenantId && token) {
      setLoading(true);
      const response = await ClientService.recordConsent(tenantId, token);
      if (response.status === 200) {
        setLoading(false);
      } else {
        setError("Error in requesting consent");
        setLoading(false);
      }
    } else {
      setError("Invalid URL");
    }
  };

  const renderError = () => {
    let thisError = error;
    let errorMessage = queryString.get("error_description");
    if (adminConsent !== "True") {
      thisError = "Not Allowed";
      errorMessage =
        "Without your consent, AP Logic will not be able to perform your free Teams analysis. Please contact Sophia Lam, your Client Relationship Coordinator, at 805-769-9201 to discuss options.";
    }
    return (
      <Result
        status="error"
        title={thisError?.replace(/_/g, " ")}
        subTitle={errorMessage || "Please check your URL and try again"}
      />
    );
  };

  const renderLoading = () => {
    return (
      <Result
        className="ant-result-loading"
        icon={<Spin size="large" />}
        title="Please wait..."
        subTitle="Recording Consent"
      />
    );
  };

  const requestingConsent = () => {
    if (loading) {
      return renderLoading();
    }
    return (
      <Result
        status="success"
        title="Consent Recorded Successfully"
        subTitle="Thank you for providing your consent! Your AP Logic Consultant will contact you in the next few days to review your analysis results."
      />
    );
  };

  return (
    <div className="request-consent-view">{error || adminConsent !== "True" ? renderError() : requestingConsent()}</div>
  );
};

export default RecordConsent;
