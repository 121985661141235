import FileIcon from "../../assets/images/file-icon.png";
import FolderIcon from "../../assets/images/folder-icon.png";
import LibraryIcon from "../../assets/images/library-icon.png";
import ChannelIcon from "../../assets/images/channel-icon.png";
import SharepointIcon from "../../assets/images/sharepoint-icon.png";
import SharedChannelIcon from "../../assets/images/shared-channel-icon.png";
import PrivateChannelIcon from "../../assets/images/private-channel-icon.png";

interface ITypeIcon {
  type: number | undefined;
}

export const TypeIcon = (props: ITypeIcon) => {
  let icon = FileIcon;
  if (props.type === 5) {
    icon = FolderIcon;
  } else if (props.type === 4) {
    icon = SharepointIcon;
  } else if (props.type === 7) {
    icon = LibraryIcon;
  } else if (props.type === 8) {
    icon = ChannelIcon;
  } else if (props.type === 9) {
    icon = PrivateChannelIcon;
  } else if (props.type === 10) {
    icon = SharedChannelIcon;
  }

  return <img src={icon} />;
};
