import { FC, useState } from "react";
import { useRoutes } from "react-router-dom";

import PAGES from "./utils/pages";
import { AppContext } from "./Context";
import { IPage } from "./interfaces/common";

import Auth from "./pages/Auth";
import NoAccess from "./pages/Auth/no-access";
import RecordConsent from "./pages/RecordConsent";

import NotFound from "./pages/NotFound";
import PrivateOutlet from "./PrivateOutlet";

import RoiCalculator from "./iframe/RoiCalculator";

import { URL } from "./utils/constants";

const MyRoutes: FC = () => {
  const [context, setContext] = useState({
    dreamDepartments: 0,
    keyFiles: 0,
  });

  const pages = PAGES.map((p: IPage) => ({
    path: p.link,
    element: p.element,
  }));

  return (
    <AppContext.Provider value={[context, setContext]}>
      {useRoutes([
        { path: URL.LOGIN, element: <Auth /> },
        { path: URL.RECORD_CONSENT, element: <RecordConsent /> },
        { path: URL.NO_ACCESS, element: <NoAccess /> },
        { path: URL.IFRAME_ROI, element: <RoiCalculator /> },
        {
          path: "/",
          element: <PrivateOutlet />,
          children: pages,
        },
        { path: "*", element: <NotFound /> },
      ])}
    </AppContext.Provider>
  );
};

export default MyRoutes;
