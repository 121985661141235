import { Configuration, PopupRequest, PublicClientApplication } from "@azure/msal-browser";

const scopes = [`${process.env.REACT_APP_MICROSOFT_SCOPE}`, "profile", "openid", "user.read"];

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: `${process.env.REACT_APP_MICROSOFT_CLIENT_ID}`,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_MICROSOFT_TENANT_ID}`,
    redirectUri: "/login",
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: scopes,
};
