import { FC, useEffect, useState } from "react";

import { format } from "date-fns";
import kebabCase from "lodash/kebabCase";
import { BsFullscreen } from "react-icons/bs";

import { Button, Collapse } from "antd";
import type { CollapseProps } from "antd/es/collapse";
import { CheckOutlined, ExclamationOutlined } from "@ant-design/icons";

import DepartmentNodes from "./DepartmentNodes";
import SelectNodeModal from "./SelectNodeModal";
import PickTemplateModal from "./PickTemplateModal";
import ChangeDeptTypeModal from "./ChangeDeptTypeModal";
import ApproveDepartmentModal from "./ApproveDepartmentModal";
import DepartmentWalkThroughModal from "./DepartmentWalkThroughModal";

import { ITreeNodeData } from "../../../interfaces/common";
import DreamDepartmentService from "../../../services/dreamDepartment.service";
import { IDreamDepartment, IDreamTemplates, IDreamTemplateNodes } from "../../../interfaces/dreamDepartment";

import { APPROVAL_STATUS } from "../../../utils/data";
import { getFromStorage, removeFromStorage } from "../../../utils/utility";

interface IDeptNodes {
  departmentId: number;
  nodes: IDreamTemplateNodes[];
}

interface IDepartmentListProps {
  isFirstTime: boolean;
  data: IDreamDepartment[];
  fetchSummary: () => Promise<void>;
  setIsFirstTime: (bool: boolean) => void;
  setData: (data: IDreamDepartment[]) => void;
}

const currentClientStore = getFromStorage("ifpCurrentClient");
const clientId = Number(currentClientStore);

const DepartmentList: FC<IDepartmentListProps> = ({ data, isFirstTime, setData, setIsFirstTime, fetchSummary }) => {
  const [activeKeys, setActiveKeys] = useState<string[]>([]);
  const [deptNodes, setDeptNodes] = useState<IDeptNodes[]>([]);
  const [isLoadingNodes, setIsLoadingNodes] = useState<number>();
  const [templates, setTemplates] = useState<IDreamTemplates[]>([]);
  const [showSelectNodeModal, setShowSelectNodeModal] = useState<number>();
  const [showApproveDeptModal, setShowApproveDeptModal] = useState<number>();
  const [isLoadingTemplates, setIsLoadingTemplates] = useState<boolean>(false);
  const [showPickTemplateModal, setShowPickTemplateModal] = useState<IDreamDepartment>();
  const [showApplyTemplateModal, setShowApplyTemplateModal] = useState<IDreamDepartment>();
  const [showChangeDeptTypeModal, setShowChangeDeptTypeModal] = useState<IDreamDepartment>();

  useEffect(() => {
    (async () => {
      if (showPickTemplateModal) {
        if (isFirstTime) {
          setIsFirstTime(false);
          removeFromStorage("isFirstLogin");
        }
        setIsLoadingTemplates(true);
        await fetchTemplates(showPickTemplateModal.id);
        setIsLoadingTemplates(false);
      }
    })();
  }, [showPickTemplateModal]);

  const fetchDepartmentById = async (departmentId: number, set = true) => {
    const response = await DreamDepartmentService.getById(departmentId);
    if (response?.status === 200) {
      const thisDepartmentRes = response.data as IDreamDepartment;
      if (set) {
        const thisData = data.map(d => {
          if (d.id === departmentId) {
            const value: IDreamDepartment = {
              id: departmentId,
              name: thisDepartmentRes.name,
              type: thisDepartmentRes.type,
              status: thisDepartmentRes.status,
              isAlert: false,
              lastStatusUpdatedBy: thisDepartmentRes.lastStatusUpdatedBy,
              lastStatusUpdatedAt: thisDepartmentRes.lastStatusUpdatedAt,
              totalTemplates: thisDepartmentRes.totalTemplates,
              hasNodes: thisDepartmentRes.hasNodes,
              notes: thisDepartmentRes.notes,
              isActivated: thisDepartmentRes.isActivated,
              totalApproved: thisDepartmentRes.totalApproved,
            };
            return value;
          } else {
            return { ...d };
          }
        });
        setData(thisData);
        if (thisData?.length) {
          const allApproved = thisData.every((d: IDreamDepartment) => d.status === 1);
          if (allApproved) {
            await fetchSummary();
          }
        }
      } else {
        return thisDepartmentRes;
      }
    }
  };

  const fetchDepartmentNodes = async (departmentId: number) => {
    setIsLoadingNodes(departmentId);
    const thisData = [...data].map((department: IDreamDepartment) => {
      if (department.id === departmentId && department.isAlert) {
        return {
          ...department,
          isAlert: false,
        };
      } else {
        return department;
      }
    });
    setData(thisData);
    const thisDepartmentNodes = deptNodes.filter(d => d.departmentId !== departmentId);
    const response = await DreamDepartmentService.getNodes(departmentId);
    if (response?.status === 200) {
      thisDepartmentNodes.push({
        departmentId: departmentId,
        nodes: response.data as IDreamTemplateNodes[],
      });
      setDeptNodes(thisDepartmentNodes);
      setIsLoadingNodes(undefined);
      return response;
    }
    setIsLoadingNodes(undefined);
  };

  const fetchTemplates = async (id: number) => {
    const response = await DreamDepartmentService.getTemplates(id);
    if (response?.status === 200) {
      setTemplates(response.data);
    }
  };

  const handleSkip = async (departmentId: number) => {
    if (departmentId && data) {
      const response = await DreamDepartmentService.changeStatus(departmentId, "skip", clientId);
      if (response?.status === 200) {
        const thisData = [...data].map((department: IDreamDepartment) => {
          if (department.id === departmentId && department.status === 0) {
            return {
              ...department,
              status: 2,
            };
          } else {
            return department;
          }
        });
        setData(thisData);
        const thisActiveKeys = [...activeKeys];
        thisActiveKeys.push(`${departmentId}`);
        onChange(thisActiveKeys, thisData);
      }
    }
  };

  const onChange = async (key: string | string[], departments: IDreamDepartment[]) => {
    if (typeof key !== "string") {
      if (key.length > activeKeys.length) {
        const currentKey = typeof key === "string" ? key : key[key.length - 1];
        const thisData = departments.find(d => d.id === Number(currentKey) && [0, 1, 2, 4].includes(d.status));
        if (thisData) {
          setActiveKeys(key);
          await fetchDepartmentNodes(Number(currentKey));
        }
      } else {
        const diffArr = activeKeys.filter(x => !key.includes(x));
        if (diffArr) {
          const removedKey = diffArr[0];
          const thisDepartmentNodes = deptNodes.filter(d => d.departmentId !== Number(removedKey));
          setDeptNodes(thisDepartmentNodes);
        }
        setActiveKeys(key);
      }
    }
  };

  const handleAssignTemplate = async (departmentId: number, templateId: number, dreamStructureNodeId = 0) => {
    const response = await DreamDepartmentService.assignTemplates(
      departmentId,
      templateId,
      clientId,
      dreamStructureNodeId
    );
    if (response?.status === 200) {
      await fetchDepartmentNodes(departmentId);
      await fetchDepartmentById(departmentId);
    }
  };

  const renderPickTemplateModal = () => {
    const handleClose = async () => {
      setShowPickTemplateModal(undefined);
      setShowApplyTemplateModal(undefined);
    };

    const handleSelectTemplate = async (templateId: number): Promise<void> => {
      if (showPickTemplateModal) {
        const response = await fetchDepartmentById(showPickTemplateModal.id, false);
        console.log(">>> handleSelectTemplate", response);
        if (response?.hasNodes) {
          setShowSelectNodeModal(templateId);
        } else {
          await handleAssignTemplate(showPickTemplateModal.id, templateId);
          const thisActiveKeys = [...activeKeys];
          thisActiveKeys.push(`${showPickTemplateModal.id}`);
          onChange(thisActiveKeys, data);
          handleClose();
        }
      }
    };

    const skipTemplate = async () => {
      if (showPickTemplateModal) {
        await handleSkip(showPickTemplateModal.id);
        handleClose();
      }
    };

    return (
      <PickTemplateModal
        templates={templates}
        handleSkip={skipTemplate}
        handleClose={handleClose}
        data={showPickTemplateModal}
        isLoadingTemplates={isLoadingTemplates}
        handleSelectTemplate={handleSelectTemplate}
        isApplyModal={Boolean(showApplyTemplateModal)}
      />
    );
  };

  const renderSelectNodeModal = () => {
    let nodes: IDreamTemplateNodes[] = [];
    if (showPickTemplateModal) {
      // const excludedDepartmentNodes = deptNodes.filter(d => d.departmentId !== showPickTemplateModal.id);
      const thisDeptNodes = deptNodes.find(d => d.departmentId === showPickTemplateModal.id);
      nodes = thisDeptNodes?.nodes || [];
    }

    const handleClose = () => {
      setShowSelectNodeModal(undefined);
    };

    const handleSelectNode = async (dreamStructureNodeId: number) => {
      if (showPickTemplateModal && showSelectNodeModal) {
        await handleAssignTemplate(showPickTemplateModal.id, showSelectNodeModal, dreamStructureNodeId);
        handleClose();
        setShowPickTemplateModal(undefined);
        setShowApplyTemplateModal(undefined);
      }
    };

    return (
      <SelectNodeModal
        nodes={nodes}
        handleClose={handleClose}
        templateId={showSelectNodeModal}
        department={showPickTemplateModal}
        handleSelectNode={handleSelectNode}
      />
    );
  };

  const renderChangeDeptTypeModal = () => {
    const handleClose = () => {
      setShowChangeDeptTypeModal(undefined);
    };

    const handleChange = async (departmentId: number, type: number) => {
      const values = [
        {
          value: type,
          path: `/type`,
          op: "replace",
        },
      ];
      const response = await DreamDepartmentService.update(departmentId, values);
      if (response?.status === 200) {
        const thisData = [...data].map((department: IDreamDepartment) => {
          if (department.id === departmentId) {
            return {
              ...department,
              type: type,
            };
          } else {
            return department;
          }
        });
        setData(thisData);
        await fetchDepartmentNodes(departmentId);
      }
      handleClose();
    };

    return (
      <ChangeDeptTypeModal department={showChangeDeptTypeModal} handleClose={handleClose} handleChange={handleChange} />
    );
  };

  const renderApproveDepartmentModal = () => {
    const handleClose = () => {
      setShowApproveDeptModal(undefined);
    };

    const handleApprove = async (departmentId: number) => {
      const response = await DreamDepartmentService.changeStatus(departmentId, "approve", clientId);
      if (response?.status === 200) {
        await fetchDepartmentById(departmentId);
      }
      handleClose();
    };

    return (
      <ApproveDepartmentModal
        departmentId={showApproveDeptModal}
        handleClose={handleClose}
        handleChange={handleApprove}
      />
    );
  };

  const renderDepartmentWalkThroughModal = () => {
    const handleClose = () => {
      setIsFirstTime(false);
      removeFromStorage("isFirstLogin");
    };

    const skipTemplate = async (departmentId: number) => {
      await handleSkip(departmentId);
    };

    return (
      <DepartmentWalkThroughModal
        departments={data}
        handleSkip={skipTemplate}
        isFirstTime={isFirstTime}
        handleClose={handleClose}
        setShowPickTemplateModal={department => setShowPickTemplateModal(department)}
      />
    );
  };

  const renderCollapse = () => {
    const renderLabel = (department: IDreamDepartment) => {
      let ico = null;
      if (department.status === 1) {
        ico = <CheckOutlined className="success" />;
      } else if (department.isAlert) {
        ico = <ExclamationOutlined className={department.status === 4 ? "failed" : "warning"} />;
      }
      return (
        <span>
          {ico}
          {department.name}
        </span>
      );
    };

    const renderCollapsePanelHeaderExtra = (department: IDreamDepartment) => {
      let text = "";
      if (department.status === 2) {
        text = "Customize and approve your department structure";
      } else if (department.status === 0) {
        if (department.totalTemplates) {
          text = `You have ${department.totalTemplates} pre-selected templates to choose from`;
        } else {
          text = "Customize and approve your department structure";
        }
      } else if (department.status === 1) {
        text = `approved by ${department.lastStatusUpdatedBy} at ${format(
          new Date(department.lastStatusUpdatedAt),
          "hh:mm aaaa, MM/dd/yyyy"
        )}`;
      } else if (department.status === 4) {
        if (department.totalApproved > 1) {
          text = `This department has been approved ${department.totalApproved} times, but has changes and needs to be re-approved`;
        } else {
          text = "This department has changes and needs to be re-approved";
        }
      }

      const renderActionBtn = () => {
        if (activeKeys.includes(`${department.id}`)) {
          return <Button className="btn-view">Close</Button>;
        } else {
          if (department.status === 0) {
            if (department.totalTemplates) {
              return (
                <Button
                  className="btn-pick"
                  icon={<BsFullscreen />}
                  onClick={event => {
                    event.stopPropagation();
                    setShowPickTemplateModal(department);
                  }}
                >
                  Pick Your Template
                </Button>
              );
            } else {
              return <Button>Customize Department</Button>;
            }
          } else if (department.status === 2) {
            return <Button>Customize Department</Button>;
          } else if (department.status === 1) {
            return <Button className="btn-view">View</Button>;
          } else if (department.status === 4) {
            return <Button className="btn-need-approve">View & Re-Approve</Button>;
          } else {
            return null;
          }
        }
      };

      return (
        <div className="ant-collapse-extra__actions-container">
          <span
            onClick={event => {
              event.stopPropagation();
            }}
            className="status-text"
          >
            {text}
          </span>
          {renderActionBtn()}
        </div>
      );
    };

    const renderCollapsePanelChildren = (department: IDreamDepartment) => {
      const excludedDepartmentNodes = deptNodes.filter(d => d.departmentId !== department.id);
      const thisDeptNodes = deptNodes.find(d => d.departmentId === department.id);
      const nodes: IDreamTemplateNodes[] = thisDeptNodes?.nodes || [];

      const handleDeleteNode = async (id: number) => {
        const response = await DreamDepartmentService.deleteNode(department.id, id);
        if (response?.status === 200) {
          await fetchDepartmentNodes(department.id);
          await fetchDepartmentById(department.id);
        }
      };

      const handleEditNode = async (nodeId: number, values: ITreeNodeData) => {
        const patchDocument = [
          {
            value: values.name,
            path: `/name`,
            op: "replace",
          },
          {
            value: values.type,
            path: `/type`,
            op: "replace",
          },
        ];

        const response = await DreamDepartmentService.updateNode(department.id, nodeId, patchDocument);
        if (response?.status === 200) {
          const thisExcludedDepartmentNodes = structuredClone(excludedDepartmentNodes);
          const thisNodes = nodes.map((d: IDreamTemplateNodes) => {
            if (d.id === nodeId) {
              return {
                ...d,
                ...values,
              };
            }
            return d;
          });
          thisExcludedDepartmentNodes.push({
            departmentId: department.id,
            nodes: thisNodes,
          });
          setDeptNodes(thisExcludedDepartmentNodes);
        }
      };

      const handleDropNode = async (dragSourceId: number, dropTargetId: number) => {
        const patchDocument = [
          {
            value: `${dropTargetId}`,
            path: `/parentid`,
            op: "replace",
          },
        ];
        const response = await DreamDepartmentService.updateNode(department.id, dragSourceId, patchDocument);
        if (response?.status === 200) {
          const thisExcludedDepartmentNodes = structuredClone(excludedDepartmentNodes);
          const thisNodes = nodes.map((d: IDreamTemplateNodes) => {
            if (d.id === dragSourceId) {
              return {
                ...d,
                parentId: dropTargetId,
              };
            }
            return d;
          });
          thisExcludedDepartmentNodes.push({
            departmentId: department.id,
            nodes: thisNodes,
          });
          setDeptNodes(thisExcludedDepartmentNodes);
        }
      };

      const handleAddNode = async (name: string) => {
        const response = await DreamDepartmentService.addNode(department.id, name);
        if (response?.status === 200) {
          const thisNodes = structuredClone(nodes);
          const thisExcludedDepartmentNodes = structuredClone(excludedDepartmentNodes);
          thisNodes.unshift({
            id: response.data,
            name: name,
            type: 5,
            dreamStructureId: department.id,
            parentId: null,
            isValidType: true,
            owner: null,
            purpose: null,
            permissionNotes: null,
            notes: null,
          });
          thisExcludedDepartmentNodes.push({
            departmentId: department.id,
            nodes: thisNodes,
          });
          setDeptNodes(thisExcludedDepartmentNodes);

          const thisData = data.map(d => {
            if (d.id === department.id) {
              return {
                ...d,
                hasNodes: true,
              };
            }
            return d;
          });
          setData(thisData);
        }
      };

      const handleMultiDelete = async (ids: number[]) => {
        const response = await DreamDepartmentService.deleteNodes(department.id, ids);
        if (response?.status === 200) {
          await fetchDepartmentNodes(department.id);
          await fetchDepartmentById(department.id);
        }
      };

      return (
        <DepartmentNodes
          nodes={nodes}
          department={department}
          handleAddNode={handleAddNode}
          isLoadingNodes={isLoadingNodes}
          handleEditNode={handleEditNode}
          handleDropNode={handleDropNode}
          handleDeleteNode={handleDeleteNode}
          handleMultiDelete={handleMultiDelete}
          setShowApplyTemplateModal={() => setShowApplyTemplateModal(department)}
          setShowPickTemplateModal={() => setShowPickTemplateModal(department)}
          setShowApproveDeptModal={() => setShowApproveDeptModal(department.id)}
          setShowChangeDeptTypeModal={() => setShowChangeDeptTypeModal(department)}
        />
      );
    };

    const items: CollapseProps["items"] = data?.map((d: IDreamDepartment) => {
      const status = APPROVAL_STATUS.find(a => a.id === d.status)?.name || "none";

      return {
        key: d.id,
        label: renderLabel(d),
        showArrow: false,
        children: renderCollapsePanelChildren(d),
        extra: renderCollapsePanelHeaderExtra(d),
        className: `status-${kebabCase(status)}`,
      };
    });

    return (
      <Collapse
        ghost
        items={items}
        onChange={(key: string | string[]) => onChange(key, data)}
        destroyInactivePanel
        activeKey={activeKeys}
        className="dream-department-page__collapse"
      />
    );
  };

  return (
    <>
      {renderCollapse()}

      {renderSelectNodeModal()}
      {renderPickTemplateModal()}
      {renderChangeDeptTypeModal()}
      {renderApproveDepartmentModal()}
      {renderDepartmentWalkThroughModal()}
    </>
  );
};

export default DepartmentList;
