import { FC, useState } from "react";

import { Button, notification } from "antd";
import { ArrowDownOutlined, CheckCircleFilled } from "@ant-design/icons";

import Loading from "../../../components/Loading";
import DragDrop from "../../../components/DragDrop";

import { ITreeStructure } from "../../../interfaces/common";
import { DEPARTMENT_TYPE } from "../../../utils/data";
import { IDreamTemplates, IDreamTemplateNodes } from "../../../interfaces/dreamDepartment";

interface IDreamTemplatesProps {
  isLoading?: boolean;
  hasNodes?: boolean;
  data: IDreamTemplates[];
  handleSelectTemplate: (templateId: number) => Promise<void>;
}

const DreamTemplates: FC<IDreamTemplatesProps> = ({ isLoading, data, hasNodes, handleSelectTemplate }) => {
  const [api, contextHolder] = notification.useNotification();

  const [isApplying, setIsApplying] = useState<number>();

  if (isLoading) {
    return <Loading message="Loading Dream Templates" />;
  }

  const openNotificationWithIcon = () => {
    if (!hasNodes) {
      api.success({
        message: "Success",
        description: "Template Selected",
      });
    }
  };

  const renderNodes = (template: IDreamTemplates) => {
    const treeData: ITreeStructure[] = template.nodes.map((node: IDreamTemplateNodes) => {
      let isExpandable = false;
      for (let i = 0; i <= template.nodes.length; i++) {
        if (template.nodes[i]?.parentId === node.id) {
          isExpandable = true;
          break;
        }
      }

      return {
        id: node.id,
        parent: node.parentId ? node.parentId : 0,
        droppable: false,
        text: node.name,
        data: {
          type: node.type,
          isExpandable: isExpandable,
        },
      };
    });

    return <DragDrop treeData={treeData} />;
  };

  const renderTemplates = (template: IDreamTemplates, index: number) => {
    const handelApplyTemplate = async () => {
      if (template.status === 1) {
        return false;
      }
      setIsApplying(template.id);
      await handleSelectTemplate(template.id);
      openNotificationWithIcon();
      setIsApplying(undefined);
    };

    const type = DEPARTMENT_TYPE.find(s => s.value === template.type);
    const isApplyingTemplate = isApplying === template.id;

    return (
      <section className="dream-template-section__template" key={template.id}>
        <section className="dream-template-section__template__header">
          <Button
            icon={template.status === 1 ? <CheckCircleFilled /> : <ArrowDownOutlined />}
            className={template.status === 1 ? "btn-approved" : ""}
            onClick={handelApplyTemplate}
            loading={isApplyingTemplate}
          >
            Select Template
          </Button>
          <h1>
            {template.alias}
            <span>{type?.label}</span>
          </h1>
        </section>
        <section className="dream-template-section__template__nodes">{renderNodes(template)}</section>
      </section>
    );
  };

  return (
    <>
      {contextHolder}
      <section className="dream-template-section">{data?.map(renderTemplates)}</section>
    </>
  );
};

export default DreamTemplates;
